import React from 'react'
import './portfolio.css'
import Trackermon from '../../assets/Trackermon.jpg'
import Sussus from '../../assets/sussusdukus.jpg'
import HSJ from '../../assets/hopskipjump.jpg'
import PixelPlatformer from '../../assets/pixelplatformer.jpg'

const data = [
  {
    id: 1,
    image: Trackermon,
    title: 'Trackermon',
    github: 'https://github.com/Ardentsoul/tp.git'
  },
  {
    id: 2,
    image: Sussus,
    title: 'Sussus Dukus',
    github: 'https://github.com/Ardentsoul/ip.git'
  },
  {
    id: 3,
    image: HSJ,
    title: 'Hop Skip Jump',
    github: 'https://github.com/Ardentsoul/Hop-Skip-Jump.git'
  },
  {
    id: 4,
    image: PixelPlatformer,
    title: 'Pixel Platformer',
    github: 'https://github.com/Ardentsoul/Pixel-Platformer.git'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title}/>
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank'>Github</a>
              </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio