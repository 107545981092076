import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt='About Image' />
          </div>
        </div>

        <div className="about__content">
          <p>
            Hello there! I'm a current undergraduate at the National University of Singapore pursuing Computer Science. I have a deep interest about game related technologies and networking. I also enjoy interacting and working with people. When I'm not glued to my computer screen, you can find me either running or bouldering to keep myself fit.
          </p>

          <a href='#contact' className='btn btn-primary'>Let's Chat</a>
        </div>
      </div>
      </section>
  )
}

export default About